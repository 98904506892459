<template>
  <div>
    <div v-if="list.physical.length > 0" class="content-container-box p-2">
      <div
        :class="[index != list.physical.length - 1 ? 'mb-3' : '']"
        v-for="(item, index) in list.physical"
        :key="index"
        @click="insertRedeem(item.id, item.product_type, item.redeem)"
      >
        <div
          :class="[
            list.out_of_stock
              ? 'content-privilege-group-fade1 shadow-sm'
              : 'content-privilege-group1 shadow-sm',
            item.is_theme == 1 ? createStyle(item, index) : ''
          ]"
          :ref="`banner_${index}${type ? `_${type}` : ''}`"
        >
          <b-row no-gutters>
            <b-col cols="5">
              <div>
                <b-img-lazy
                  v-if="item.image_url"
                  v-bind="mainProps"
                  :src="item.image_url"
                  class="lazy-image"
                  alt="Image 1"
                ></b-img-lazy>
                <b-img-lazy
                  v-else
                  v-bind="mainProps"
                  :src="require('@/assets/images/default-image.png')"
                  class="lazy-image"
                  alt="Image 1"
                ></b-img-lazy>
              </div>
            </b-col>
            <b-col class="panel-detail-reward-list">
              <div class="title line-clamp-2">
                {{ item.product_name }}
              </div>
              <div>
                <b-badge pill variant="list-redeem">
                  <div class="point d-flex align-items-center">
                    <span class="fa-stack fa-1x icon-background mr-2">
                      <b-img
                        :src="
                          $store.state.domain + $store.state.theme.point_logo
                        "
                        class="img-point"
                        v-if="$store.state.theme.point_logo"
                      ></b-img>
                      <!-- {{ $store.state.domain + $store.state.theme.point_logo }} -->
                      <font-awesome-icon
                        v-else
                        icon="star"
                        class="point-icon-color"
                        :color="item.theme_icon_color"
                      />
                    </span>
                    {{ item.point_used | numeral("0,0") }}
                  </div>
                </b-badge>
              </div>
              <div
                class="description line-clamp-2 text-gray"
                v-html="getDescription(item.description)"
              ></div>
              <div>
                <b-badge
                  variant="expired"
                  v-if="$store.state.theme.line_display_redeem_expire == '1'"
                >
                  แลกได้ถึง : {{ dateFormat(item.valid_to) }}
                </b-badge>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div v-else-if="list.physical.length == 0 && search">
      <div class="notfound-data">ขออภัยด้วย ไม่พบของขวัญ</div>
    </div>
    <div v-else>
      <div class="notfound-data">ไม่พบของขวัญของคุณ</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    point: {
      required: true,
      type: Number
    },
    list: {
      required: true,
      type: Object
    },
    type: {
      required: false
    },
    search: {
      required: false
    }
  },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 600,
        height: 600,
        class: ""
      }
    };
  },
  methods: {
    handleClick(item, type, o_id) {
      console.log(item);
      if (item) {
        this.$router.push({
          path: `/reward/${item}?type=${type}&o_id=${o_id}&page=history`
        });
      }
    },
    createStyle(item, index) {
      let id = index;
      let className = `banner_${id}${this.type ? `_${this.type}` : ""}`;
      function hexToRgb(hex, opacity) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
          ? `rgba(${parseInt(result[1], 16)},${parseInt(
              result[2],
              16
            )},${parseInt(result[3], 16)},${opacity})`
          : null;
      }
      this.$nextTick(() => {
        var style = document.createElement("style");
        style.innerHTML = `.${className} { `;
        style.innerHTML += this.styleSheet(item);
        style.innerHTML += " }";
        style.innerHTML += `.${className} .icon-background{ background-color:${item.theme_icon_color} }`;
        style.innerHTML += `.${className} .description{ color:${item.theme_secondary_color} }`;
        style.innerHTML += `.${className} .btn-redeem { color:${item.theme_primary_color} }`;
        style.innerHTML += `.${className} .badge-list-redeem { border:1px solid ${
          item.theme_icon_color
        }; background:${hexToRgb(item.theme_icon_color, 0.1)} }`;

        document.getElementsByTagName("head")[0].appendChild(style);
        let element = this.$refs[className][0];
        element.classList.add(className);
      });
    },
    styleSheet(item) {
      return `
      background-image:    ${
        item.theme_background_page == 1
          ? `linear-gradient(45deg, ${item.theme_background}, ${item.theme_background})`
          : `url(${item.theme_background})`
      } ;
      color: ${item.theme_primary_color}`;
    },
    insertRedeem(id, type) {
      this.$router.push(
        `/reward/${id}?type=${type}&o_id=0${
          this.type ? "&page=" + this.type : ""
        }`
      );
    },
    insertRedeemPrivilege(data) {},
    dateFormat(date) {
      return this.$moment(date).add(543, "y").format("DD MMM YYYY");
    },
    getDescription(item) {
      return item?.replace(/(<([^>]+)>)/gi, "");
    }
  }
};
</script>

<style lang="scss" scoped>
.total-point {
  border-bottom: 1px solid gray;
}
.totalpoint-font {
  font-weight: 600;
  font-size: var(--text-xl);
}
.lazy-image {
  font-size: 1px;
  -o-object-fit: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.content-reward-group {
  border-radius: 5px;
  background-color: #f0f0f0;
}
.title {
  font-size: var(--text-lg);
  font-weight: 600;
}

.line-clamp-2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.until-date {
  font-size: var(--text-md);
}
.status-false {
  opacity: 0.6;
}
.btn-redeem {
  background-color: transparent;
  color: #000;
  border: none;
}
.line-top {
  border-bottom: 1px dashed #aaaaaa;
  margin: 0 0;
  // padding: 5px 0;
  // border-bottom: 1px dashed rgb(170, 170, 170);
}
.point {
  font-size: var(--text-md);
  font-weight: 600;
  // color: green;
}

.point-icon-color {
  color: #fff;
  // padding-left: 4px;
  padding: 0px 2px;
}
.icon-background {
  background-color: var(--reward-icon-color);
  height: 20px;
  width: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.out-of-stock {
  color: red;
  font-size: var(--text-md);
  font-weight: 600;
}
// .highlight {
//   background-image: var(--reward-backgroud-color);
//   color: var(--reward-color);
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center;
//   .btn-redeem {
//     color: var(--reward-color);
//   }
//   .description {
//     color: var(--reward-secondary-color);
//   }
// }
.panel-detail-reward-list {
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
}
</style>
