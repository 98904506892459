var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.list.length > 0)?_c('div',{staticClass:"content-container-box p-2"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:[index != _vm.list.length - 1 ? 'mb-2' : '']},[_c('div',{ref:`banner_${index}${_vm.type ? `_${_vm.type}` : ''}`,refInFor:true,class:[
          _vm.list.out_of_stock
            ? 'content-privilege-group-fade1 shadow'
            : 'content-privilege-group1 shadow',
          item.is_theme == 1 ? _vm.createStyle(item, index) : ''
        ]},[_c('b-row',{staticClass:"line-top"},[_c('b-col',{staticClass:"p-0",attrs:{"cols":"5"}},[_c('div',[(item.image_url)?_c('b-img-lazy',_vm._b({staticClass:"lazy-image",attrs:{"src":item.image_url,"alt":"Image 1"}},'b-img-lazy',_vm.mainProps,false)):_c('b-img-lazy',_vm._b({staticClass:"lazy-image",attrs:{"src":require('@/assets/images/default-image.png'),"alt":"Image 1"}},'b-img-lazy',_vm.mainProps,false))],1)]),_c('b-col',{staticClass:"p-3"},[_c('div',{staticClass:"title line-clamp-2"},[_vm._v(" "+_vm._s(item.product_name)+" ")]),_c('b-badge',{staticClass:"mt-2",attrs:{"pill":"","variant":"list-redeem"}},[_c('div',{staticClass:"point d-flex align-items-center"},[_c('span',{staticClass:"fa-stack fa-1x icon-background mr-2"},[(_vm.$store.state.theme.point_logo)?_c('b-img',{staticClass:"img-point",attrs:{"src":_vm.$store.state.domain + _vm.$store.state.theme.point_logo}}):_c('font-awesome-icon',{staticClass:"point-icon-color",attrs:{"icon":"star","color":item.theme_icon_color}})],1),_vm._v(" "+_vm._s(_vm._f("numeral")(item.point_used,"0,0"))+" ")])]),_c('div',{staticClass:"description line-clamp-2 text-gray mt-2",domProps:{"innerHTML":_vm._s(
                _vm.getDescription(item.redeemOrderListDatas[0].description)
              )}})],1)],1),_c('div',{staticClass:"px-3 py-2"},[_c('b-row',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('redeem-detail-' + index),expression:"'redeem-detail-' + index"}],staticClass:"align-items-center justify-content-between"},[_c('b-col',{attrs:{"cols":"10"}},[_c('small',{staticClass:"mr-2"},[_vm._v("ประวัติการแลกทั้งหมด")]),_c('b-badge',{staticClass:"border-none",attrs:{"variant":"list-redeem"}},[_vm._v(" "+_vm._s(item.number_redeem)+" รางวัล ")])],1),_c('b-col',{staticClass:"text-right"},[(_vm.visible.includes(index))?_c('font-awesome-icon',{staticClass:"text-gray",attrs:{"icon":['fas', 'chevron-up']}}):_c('font-awesome-icon',{staticClass:"text-gray",attrs:{"icon":['fas', 'chevron-down']}})],1)],1),_c('b-collapse',{attrs:{"id":'redeem-detail-' + index,"accordion":'redeem-list' + index,"role":"tabpanel"},on:{"show":function($event){return _vm.handleVisible(index, 1)},"hide":function($event){return _vm.handleVisible(index, 0)}}},[_c('b-row',{staticClass:"justify-content-center align-items-center f-md"},[_c('b-col',{staticClass:"text-gray f-sm my-1",attrs:{"cols":"6"}},[_c('b',[_vm._v("วันที่แลก")])]),_c('b-col',{staticClass:"text-right text-gray f-sm my-1",attrs:{"cols":"6"}},[_c('b',[_vm._v("สถานะ")])]),_vm._l((item.redeemOrderListDatas),function(list,i){return [_c('b-col',{key:'date-' + i,attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.$moment(list.redeem_date) .add(543, "y") .format("DD MMM YYYY HH:mm"))+" ")]),_c('b-col',{key:'status-' + i,staticClass:"text-right",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(list.status_name)+" ")])]})],2)],1)],1)],1)])}),0):_c('div',[_c('div',{staticClass:"notfound-data"},[_vm._v("ไม่มีประวัติแลกของรางวัล")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }