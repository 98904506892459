<template>
  <div>
    <div v-if="list.length > 0" class="content-container-box p-2">
      <div
        :class="[index != list.length - 1 ? 'mb-2' : '']"
        v-for="(item, index) in list"
        :key="index"
      >
        <div
          :class="[
            list.out_of_stock
              ? 'content-privilege-group-fade1 shadow'
              : 'content-privilege-group1 shadow',
            item.is_theme == 1 ? createStyle(item, index) : ''
          ]"
          :ref="`banner_${index}${type ? `_${type}` : ''}`"
        >
          <b-row class="line-top">
            <b-col cols="5" class="p-0">
              <div>
                <b-img-lazy
                  v-if="item.image_url"
                  v-bind="mainProps"
                  :src="item.image_url"
                  class="lazy-image"
                  alt="Image 1"
                ></b-img-lazy>
                <b-img-lazy
                  v-else
                  v-bind="mainProps"
                  :src="require('@/assets/images/default-image.png')"
                  class="lazy-image"
                  alt="Image 1"
                ></b-img-lazy>
              </div>
            </b-col>
            <b-col class="p-3">
              <div class="title line-clamp-2">
                {{ item.product_name }}
              </div>

              <b-badge pill variant="list-redeem" class="mt-2">
                <div class="point d-flex align-items-center">
                  <span class="fa-stack fa-1x icon-background mr-2">
                    <b-img
                      :src="$store.state.domain + $store.state.theme.point_logo"
                      class="img-point"
                      v-if="$store.state.theme.point_logo"
                    ></b-img>
                    <!-- {{ $store.state.domain + $store.state.theme.point_logo }} -->
                    <font-awesome-icon
                      v-else
                      icon="star"
                      class="point-icon-color"
                      :color="item.theme_icon_color"
                    />
                  </span>
                  {{ item.point_used | numeral("0,0") }}
                </div>
              </b-badge>
              <div
                class="description line-clamp-2 text-gray mt-2"
                v-html="
                  getDescription(item.redeemOrderListDatas[0].description)
                "
              ></div>
            </b-col>
          </b-row>
          <div class="px-3 py-2">
            <b-row
              class="align-items-center justify-content-between"
              v-b-toggle="'redeem-detail-' + index"
            >
              <b-col cols="10">
                <small class="mr-2">ประวัติการแลกทั้งหมด</small>
                <b-badge variant="list-redeem" class="border-none">
                  {{ item.number_redeem }} รางวัล
                </b-badge>
              </b-col>
              <b-col class="text-right">
                <font-awesome-icon
                  :icon="['fas', 'chevron-up']"
                  v-if="visible.includes(index)"
                  class="text-gray"
                />
                <font-awesome-icon
                  v-else
                  :icon="['fas', 'chevron-down']"
                  class="text-gray"
                />
              </b-col>
            </b-row>
            <b-collapse
              :id="'redeem-detail-' + index"
              :accordion="'redeem-list' + index"
              role="tabpanel"
              @show="handleVisible(index, 1)"
              @hide="handleVisible(index, 0)"
            >
              <b-row class="justify-content-center align-items-center f-md">
                <b-col cols="6" class="text-gray f-sm my-1">
                  <b>วันที่แลก</b>
                </b-col>
                <b-col cols="6" class="text-right text-gray f-sm my-1">
                  <b>สถานะ</b>
                </b-col>

                <template v-for="(list, i) of item.redeemOrderListDatas">
                  <b-col cols="6" :key="'date-' + i">
                    {{
                      $moment(list.redeem_date)
                        .add(543, "y")
                        .format("DD MMM YYYY HH:mm")
                    }}
                  </b-col>
                  <b-col cols="6" class="text-right" :key="'status-' + i">
                    {{ list.status_name }}
                  </b-col>
                </template>
              </b-row>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="notfound-data">ไม่มีประวัติแลกของรางวัล</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    point: {
      required: true,
      type: Number
    },
    list: {
      required: true,
      type: Array
    },
    type: {
      required: false
    }
  },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 600,
        height: 600,
        class: ""
      },
      visible: []
    };
  },
  methods: {
    handleVisible(val, isShow) {
      if (isShow) {
        this.visible.push(val);
      } else {
        let index = this.visible.indexOf(val);
        this.visible.splice(index, 1);
      }
    },
    handleClick(item, type, o_id) {
      if (item) {
        this.$router.push({
          path: `/reward/${item}?type=${type}&o_id=${o_id}&page=history`
        });
      }
    },
    createStyle(item, index) {
      let id = index;
      let className = `banner_${id}${this.type ? `_${this.type}` : ""}`;
      this.$nextTick(() => {
        var style = document.createElement("style");
        style.innerHTML = `.${className} { `;
        style.innerHTML += this.styleSheet(item);
        style.innerHTML += " }";
        style.innerHTML += `.${className} .icon-background{ background-color:${item.theme_icon_color} }`;
        style.innerHTML += `.${className} .description{ color:${item.theme_secondary_color} }`;
        style.innerHTML += `.${className} .btn-redeem { color:${item.theme_primary_color} }`;

        document.getElementsByTagName("head")[0].appendChild(style);
        let element = this.$refs[className][0];
        element.classList.add(className);
      });
    },
    styleSheet(item) {
      return `
      background-image:    ${
        item.theme_background_page == 1
          ? `linear-gradient(45deg, ${item.theme_background}, ${item.theme_background})`
          : `url(${item.theme_background})`
      } ;
      color: ${item.theme_primary_color}`;
    },
    insertRedeem(id, type) {
      this.$router.push(
        `/reward/${id}?type=${type}&o_id=0${
          this.type ? "&page=" + this.type : ""
        }`
      );
    },
    insertRedeemPrivilege(data) {},
    dateFormat(date) {
      return moment(date).locale("th").format("lll").replace("เวลา", "");
    },
    getDescription(item) {
      return item?.replace(/(<([^>]+)>)/gi, "");
    }
  }
};
</script>

<style lang="scss" scoped>
.total-point {
  border-bottom: 1px solid gray;
}
.totalpoint-font {
  font-weight: 600;
  font-size: var(--text-xl);
}
.lazy-image {
  font-size: 1px;
  -o-object-fit: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.content-reward-group {
  border-radius: 5px;
  background-color: #f0f0f0;
}
.title {
  font-size: var(--text-lg);
  font-weight: 600;
}

.line-clamp-2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.until-date {
  font-size: var(--text-md);
}
.status-false {
  opacity: 0.6;
}
.btn-redeem {
  background-color: transparent;
  color: #000;
  border: none;
}
.line-top {
  border-bottom: 1px dashed #aaaaaa;
  margin: 0 0;
  // padding: 5px 0;
  // border-bottom: 1px dashed rgb(170, 170, 170);
}
.point {
  font-size: var(--text-md);
  font-weight: 600;
  // color: green;
}

.point-icon-color {
  color: #fff;
  // padding-left: 4px;
  padding: 0px 2px;
}
.icon-background {
  background-color: var(--reward-icon-color);
  height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 50px;
}

.out-of-stock {
  color: red;
  font-size: var(--text-md);
  font-weight: 600;
}
</style>
