<template>
  <div>
    <div v-if="list.physical.length > 0" class="content-container-box p-2">
      <div
        :class="[index != list.physical.length - 1 ? 'mb-2' : '']"
        v-for="(item, index) in list.physical"
        :key="index"
        @click="
          handleClick(
            item.redeem_item_id,
            item.product_type,
            item.redeem_order_id
          )
        "
      >
        <div
          :class="[
            list.out_of_stock
              ? 'content-privilege-group-fade1 shadow'
              : 'content-privilege-group1 shadow',
            item.is_theme == 1 ? createStyle(item, index) : ''
          ]"
          :ref="`banner_${index}${type ? `_${type}` : ''}`"
        >
          <b-row class="line-top">
            <b-col cols="5" class="p-0">
              <div>
                <b-img-lazy
                  v-if="item.image_url"
                  v-bind="mainProps"
                  :src="item.image_url"
                  class="lazy-image"
                  alt="Image 1"
                ></b-img-lazy>
                <b-img-lazy
                  v-else
                  v-bind="mainProps"
                  :src="require('@/assets/images/default-image.png')"
                  class="lazy-image"
                  alt="Image 1"
                ></b-img-lazy>
              </div>
            </b-col>
            <b-col>
              <div class="title line-clamp-2">
                {{ item.product_name }}
              </div>
              <div
                class="description line-clamp-2"
                v-html="getDescription(item.description)"
              ></div>
              <div class="point mt-1">
                <span class="fa-stack fa-1x icon-background">
                  <font-awesome-icon
                    icon="star"
                    class="point-icon-color"
                    :color="item.theme_icon_color"
                  />
                </span>
                {{ item.point_used }} คะแนน
              </div>
            </b-col>
          </b-row>
          <b-row class="px-2" v-if="item.redeem_date">
            <b-col cols="7" class="py-2">
              <div class="until-date">
                วันที่แลก: {{ dateFormat(item.redeem_date) }}
              </div>
            </b-col>
            <b-col cols="5" class="py-2 text-right">
              <div class="until-date">{{ item.status_name }}</div>
            </b-col>
          </b-row>
          <b-row class="px-2" v-else>
            <b-col cols="7" class="py-2">
              <div v-if="list.out_of_stock" class="out-of-stock">
                ของรางวัลหมด
              </div>
              <div
                class="f-sm"
                v-else-if="$store.state.theme.line_display_redeem_expire == '1'"
              >
                วันหมดอายุ: {{ dateFormat(item.valid_to) }}
              </div>
            </b-col>
            <b-col cols="5" class="p-0 text-right">
              <b-button
                class="btn-redeem"
                @click.prevent="
                  insertRedeem(item.id, item.product_type, item.redeem)
                "
              >
                <div class="insert-redeem w-100">ดูรายละเอียด</div>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="notfound-data">ไม่มีประวัติแลกของรางวัล</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    point: {
      required: true,
      type: Number
    },
    list: {
      required: true,
      type: Object
    },
    type: {
      required: false
    }
  },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 600,
        height: 600,
        class: ""
      }
    };
  },
  methods: {
    handleClick(item, type, o_id) {
      if (item) {
        this.$router.push({
          path: `/reward/${item}?type=${type}&o_id=${o_id}&page=history`
        });
      }
    },
    createStyle(item, index) {
      let id = index;
      let className = `banner_${id}${this.type ? `_${this.type}` : ""}`;
      this.$nextTick(() => {
        var style = document.createElement("style");
        style.innerHTML = `.${className} { `;
        style.innerHTML += this.styleSheet(item);
        style.innerHTML += " }";
        style.innerHTML += `.${className} .icon-background{ background-color:${item.theme_icon_color} }`;
        style.innerHTML += `.${className} .description{ color:${item.theme_secondary_color} }`;
        style.innerHTML += `.${className} .btn-redeem { color:${item.theme_primary_color} }`;

        document.getElementsByTagName("head")[0].appendChild(style);
        let element = this.$refs[className][0];
        element.classList.add(className);
      });
    },
    styleSheet(item) {
      return `
      background-image:    ${
        item.theme_background_page == 1
          ? `linear-gradient(45deg, ${item.theme_background}, ${item.theme_background})`
          : `url(${item.theme_background})`
      } ;
      color: ${item.theme_primary_color}`;
    },
    insertRedeem(id, type) {
      this.$router.push(
        `/reward/${id}?type=${type}&o_id=0${
          this.type ? "&page=" + this.type : ""
        }`
      );
    },
    insertRedeemPrivilege(data) {},
    dateFormat(date) {
      return moment(date).locale("th").format("lll").replace("เวลา", "");
    },
    getDescription(item) {
      return item?.replace(/(<([^>]+)>)/gi, "");
    }
  }
};
</script>

<style lang="scss" scoped>
.total-point {
  border-bottom: 1px solid gray;
}
.totalpoint-font {
  font-weight: 600;
  font-size: var(--text-xl);
}
.lazy-image {
  font-size: 1px;
  -o-object-fit: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.content-reward-group {
  border-radius: 5px;
  background-color: #f0f0f0;
}
.title {
  font-size: var(--text-lg);
  font-weight: 600;
}

.line-clamp-2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.until-date {
  font-size: var(--text-md);
}
.status-false {
  opacity: 0.6;
}
.btn-redeem {
  background-color: transparent;
  color: #000;
  border: none;
}
.line-top {
  border-bottom: 1px dashed #aaaaaa;
  margin: 0 0;
  // padding: 5px 0;
  // border-bottom: 1px dashed rgb(170, 170, 170);
}
.point {
  font-size: var(--text-md);
  font-weight: 600;
  // color: green;
}

.point-icon-color {
  color: #fff;
  // padding-left: 4px;
  padding: 0px 2px;
}

.out-of-stock {
  color: red;
  font-size: var(--text-md);
  font-weight: 600;
}
// .highlight {
//   background-image: var(--reward-backgroud-color);
//   color: var(--reward-color);
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center;
//   .btn-redeem {
//     color: var(--reward-color);
//   }
//   .description {
//     color: var(--reward-secondary-color);
//   }
// }
</style>
