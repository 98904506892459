<template>
  <NewLayout v-if="$store.state.theme.line_layout == 'Compact'" />
  <Layout v-else />
</template>

<script>
import { Layout, NewLayout } from "./layout";
export default {
  components: {
    Layout,
    NewLayout
  }
};
</script>

<style></style>
