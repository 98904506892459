<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else>
      <div
        class="d-flex align-items-center bar-color"
        style="border-bottom: 1px solid #e4e4e4"
      >
        <div
          class="d-flex align-items-center w-100"
          style="border-right: 1px solid #e4e4e4"
        >
          <div class="f-2xl px-3 py-2 f-bold">ของขวัญ</div>

          <div class="f-sm ml-auto mr-2">
            <span class="text-gray">คะแนนคงเหลือ</span>
            <div class="point d-flex align-items-center">
              <span class="fa-stack fa-1x icon-background mr-2">
                <b-img
                  :src="$store.state.domain + $store.state.theme.point_logo"
                  class="img-point"
                  v-if="$store.state.theme.point_logo"
                ></b-img>
                <!-- {{ $store.state.domain + $store.state.theme.point_logo }} -->
                <font-awesome-icon
                  v-else
                  icon="star"
                  class="point-icon-color"
                />
                <!-- <font-awesome-icon icon="star" class="point-icon-color" /> -->
              </span>
              <span class="f-lg f-bold">
                {{ totalPoint | numeral("0,0") }}</span
              >
            </div>
          </div>
        </div>

        <div class="text-center px-3">
          <font-awesome-icon
            icon="times"
            size="lg"
            style="width: 30px"
            v-if="isSearch && search"
            @click="(isSearch = false), (search = ''), getRewardList()"
          />
          <font-awesome-icon
            icon="search"
            size="lg"
            style="width: 30px"
            v-else
            @click="isSearch ? (isSearch = false) : (isSearch = true)"
          />
        </div>
      </div>
      <div class="px-2" v-if="isSearch">
        <b-input-group class="my-2">
          <b-form-input
            type="text"
            id="header-search-bar"
            class="search-bar shadow-sm"
            @keyup.enter="handleKeypress"
            placeholder="ค้นหาของขวัญ"
            v-debounce:500ms="handleKeypress"
            v-model="search"
          >
          </b-form-input>

          <b-input-text class="spinner-loading" v-if="fetching">
            <b-spinner small label="Small Spinner" variant="primary"></b-spinner
          ></b-input-text>
        </b-input-group>
      </div>
      <b-tabs v-model="tabIndex" nav-class="shadow-sm" content-class="mt-3">
        <b-tab>
          <template #title>
            <strong>ทั้งหมด</strong>
            <b-badge
              v-if="rewardsList.physical.length > 0"
              variant="color-primary"
              class="ml-1"
              pill
              >{{ rewardsList.physical.length }}</b-badge
            >
          </template>
          <RewardListNewLayout
            :search="search"
            :point="totalPoint"
            :list="rewardsList"
          />
        </b-tab>
        <b-tab title="ประวัติการแลก">
          <div v-if="isLoadHistory">
            <OtherLoading variant="dark" />
          </div>
          <div v-else>
            <div class="list-redeem-count">
              <div class="mr-auto">จำนวนรายการ</div>
              <div class="ml-auto">
                {{ rewardsHistory ? rewardsHistory.length : "0" }} รายการ
              </div>
              <div class="ml-2 cursor-pointer" @click="isShow = true">
                <font-awesome-icon
                  :icon="['fas', 'filter']"
                  style="color: black"
                />
              </div>
            </div>
            <RewardListHistoryNewLayout
              v-if="rewardsHistory"
              :point="totalPoint"
              :list="rewardsHistory"
              type="history"
            />
          </div>

          <b-sidebar
            v-model="isShow"
            id="sidebar-backdrop"
            title=""
            backdrop
            shadow
            right
            width="80vw"
            @change="onSideBarShow"
          >
            <template #header="{ hide }">
              <div class="content-between w-100">
                <span
                  class="f-2xl f-bold w-100 p-3"
                  style="border-right: 1px solid #e4e4e4"
                  >กรองข้อมูล</span
                >

                <b-button variant="link" @click="hide" class="px-3"
                  ><font-awesome-icon
                    size="lg"
                    :icon="['fas', 'times']"
                    style="color: black"
                /></b-button>
              </div>
            </template>
            <div class="mt-3 px-3">
              <div class="content-between f-bold f-md mb-3">
                <div>กำลังเลือก</div>
                <div>{{ selected.length }} รายการ</div>
              </div>
              <b-form-group label="" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="selected"
                  :options="filterRewardHistory"
                  :aria-describedby="ariaDescribedby"
                  stacked
                  class="f-bold mb-2"
                  value-field="redeem_item_id"
                  text-field="product_name"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
            <template #footer>
              <div class="d-flex">
                <b-col cols="6" class="px-0">
                  <b-button
                    squared
                    block
                    @click="submitFilter"
                    variant="primary"
                  >
                    ยืนยัน
                  </b-button>
                </b-col>
                <b-col cols="6" class="px-0">
                  <b-button
                    @click="clearFilter"
                    squared
                    block
                    variant="outline-primary"
                    >ล้างตัวกรอง</b-button
                  >
                </b-col>
              </div>
            </template>
          </b-sidebar>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";

import RewardListNewLayout from "@/components/reward/RewardListNewLayout.vue";
import RewardListHistoryNewLayout from "@/components/reward/RewardListHistoryNewLayout";
import InputText from "@/components/input/InputText";
export default {
  components: {
    RewardListNewLayout,
    OtherLoading,
    RewardListHistoryNewLayout,
    InputText
  },
  data() {
    return {
      totalPoint: this.$store.state.shortProfile.total_point,
      breadcrumb: [
        {
          text: "ของรางวัล",
          to: "/profile"
        }
      ],
      rewardsList: {},
      isLoading: true,
      rewardsHistory: null,
      filterRewardHistory: [],
      filter: {
        page: 1,
        take: 10
      },
      totalCount: 0,
      search: "",
      tabIndex: 0,
      selected: [],
      isShow: false,
      isLoadHistory: true,
      redeem_item_ids: [],
      isSearch: false,
      fetching: false
    };
  },

  created: async function () {
    try {
      await this.getRewardList();
      await this.getRewardHistory();

      if (this.$route.query) {
        if (this.$route.query.tabIndex) {
          this.tabIndex = Number(this.$route.query.tabIndex);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    clearFilter() {
      // this.selected = [];
      this.redeem_item_ids = [];
      this.getRewardHistory();
    },
    handleKeypress(e) {
      //   this.isLoading = true;
      this.fetching = true;
      this.getRewardList();
    },
    async getRewardList() {
      this.$axios
        .post(`${process.env.VUE_APP_API}/api/v1/Reward/reward_list`, {
          search: this.search
        })
        .then(
          async data => {
            this.isLoading = false;
            this.fetching = false;
            if (data.result == 1) {
              this.rewardsList = data.detail;
            }
          },
          error => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
              console.log(error);
            }
          }
        );
    },
    submitFilter() {
      this.redeem_item_ids = this.selected;
      this.getRewardHistory();
    },
    onSideBarShow(val) {
      if (val) {
        this.selected = this.redeem_item_ids;
      }
    },
    async getRewardHistory() {
      this.isShow = false;
      this.isLoadHistory = true;
      this.$axios
        .post(`${process.env.VUE_APP_API}/api/v1/Reward/group_reward_history`, {
          redeem_item_ids: this.redeem_item_ids
        })
        .then(
          data => {
            if (data.result == 1) {
              this.rewardsHistory = data.detail.groupRedeemList;
              this.filterRewardHistory = data.detail.groupFilter;
            } else {
              this.rewardsHistory = [];
            }
            this.isLoadHistory = false;
          },
          error => {
            const code = parseInt(error.response && error.response.status);
            this.isLoadHistory = false;
            if (code === 401) {
              console.log(error);
            }
          }
        );
    }
  }
};
</script>

<style lang="css" scoped>
.search-box {
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search {
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: var(--text-xl);
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  background-color: #22a6b3;
  padding-right: 40px;
  color: #fff;
}
.input-search::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: var(--text-xl);
  letter-spacing: 2px;
  font-weight: 100;
}
.btn-search {
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: var(--text-xl);
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color: #ffffff;
  background-color: transparent;
  pointer-events: painted;
}
.btn-search:focus ~ .input-search {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.input-search:focus {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}

::v-deep li.nav-item {
  width: 50%;
  text-align: center;
}

::v-deep a {
  color: #fff;
}
.title {
  color: #fff;
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: 80px;
}
.icon-history-custom {
  color: #fff;
  text-align: right;
  margin-top: -31px;
  margin-bottom: 5px;
  right: 15px;
}
::v-deep footer {
  padding: unset;
}
.spinner-loading {
  position: absolute;
  right: 2%;
  top: 50%;
  transform: translateY(-50%);
}
</style>
